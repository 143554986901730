@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Press Start 2P', cursive;
  background-color: #000;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
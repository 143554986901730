@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.console {
  width: 100%;
  height: 100vh;
  background-color: #000;
  border: 7.2px solid #00ff00;
  font-family: 'Press Start 2P', cursive;
  color: #00ff00;
  text-shadow: 0 0 4.5px #00ff00;
  box-shadow: 0 0 27px #00ff00, inset 0 0 27px #00ff00;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px;
  box-sizing: border-box;
  opacity: 0;
  transform: scale(0.91);
  transition: all 0.45s ease-in-out;
}

.powerOn {
  opacity: 1;
  transform: scale(1);
}

.screen {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.slinkImage {
  width: 80%;
  max-width: 400px;
  margin-bottom: 20px;
}

.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  font-size: 4.5vw;
  margin-bottom: 4.5vh;
  animation: neon 1.35s ease-in-out infinite alternate;
  line-height: 1.2;
  text-align: center;
}

.actionButton {
  background-color: #ff00ff;
  color: #000;
  border: none;
  padding: 13.5px 27px;
  font-family: inherit;
  font-size: 1.8vw;
  cursor: pointer;
  transition: all 0.27s;
  margin: 9px 0;
}

.actionButton:hover {
  background-color: #00ffff;
  color: #000;
  box-shadow: 0 0 18px #00ffff;
}

.connectButton {
  composes: actionButton;
}

.claimButton {
  composes: actionButton;
}

.twitterButton {
  composes: actionButton;
  background-color: #1DA1F2;
  color: #ffffff;
}

.twitterButton:hover {
  background-color: #0c85d0;
  box-shadow: 0 0 18px #1DA1F2;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
  font-size: 1.35vw;
}

.insertCoin {
  position: absolute;
  bottom: 18px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 2.7vw;
  animation: blink 0.45s infinite;
  color: #ffff00;
  text-shadow: 0 0 9px #ffff00;
}

.error {
  margin-top: 9px;
  padding: 9px;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 4.5px;
  font-size: 1.35vw;
  animation: errorBlink 0.45s infinite;
}

.scanlines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.18) 50%
  );
  background-size: 100% 3.6px;
  z-index: 2;
  pointer-events: none;
}

@keyframes neon {
  from {
    text-shadow: 0 0 4.5px #fff, 0 0 9px #fff, 0 0 13.5px #fff, 0 0 18px #00ff00, 0 0 31.5px #00ff00, 0 0 36px #00ff00, 0 0 45px #00ff00, 0 0 67.5px #00ff00;
  }
  to {
    text-shadow: 0 0 2.25px #fff, 0 0 4.5px #fff, 0 0 6.75px #fff, 0 0 9px #00ff00, 0 0 15.75px #00ff00, 0 0 18px #00ff00, 0 0 22.5px #00ff00, 0 0 33.75px #00ff00;
  }
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes errorBlink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.45; }
}

@media (max-width: 768px) {
  .title {
    font-size: 7.2vw;
    line-height: 1.5;
    margin-bottom: 3vh;
  }
  .actionButton {
    font-size: 3.6vw;
    padding: 9px 18px;
  }
  .stats, .error {
    font-size: 2.7vw;
  }
  .insertCoin {
    font-size: 4.5vw;
  }
  .slinkImage {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 8vw;
    line-height: 1.6;
  }
  .actionButton {
    font-size: 4vw;
    padding: 8px 16px;
  }
  .stats, .error {
    font-size: 3vw;
  }
  .insertCoin {
    font-size: 5vw;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.console {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #000;
  color: #00ff00;
  font-family: 'Press Start 2P', cursive;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 8px solid #ff00ff;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 60px rgba(255, 0, 255, 0.8);
  animation: flicker 0.05s infinite, rainbow-border 2s linear infinite;
}

.console::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  background: 
    linear-gradient(45deg, #ff00ff 25%, transparent 25%),
    linear-gradient(-45deg, #ff00ff 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ff00ff 75%),
    linear-gradient(-45deg, transparent 75%, #ff00ff 75%);
  background-size: 8px 8px;
  background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
  pointer-events: none;
  opacity: 0.3;
  z-index: -1;
  animation: glitch 0.1s infinite, rotate 10s linear infinite;
}

.console::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(90deg, rgba(255, 0, 0, 0.2), rgba(0, 255, 0, 0.2), rgba(0, 0, 255, 0.2));
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  animation: scanlines 0.5s linear infinite;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00;
  animation: text-flicker 0.5s infinite;
}

.credit, .highScore {
  font-size: 0.8em;
  animation: glow 0.5s ease-in-out infinite alternate;
}

.title {
  text-align: center;
  font-size: 2em;
  margin: 10px 0;
  color: #ff00ff;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
  animation: pulse 0.5s infinite, rainbow-text 2s linear infinite;
}

.websiteGrid {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  overflow-y: auto;
  padding: 10px;
  background: url('https://i.gifer.com/HGoU.gif');
  background-size: cover;
  border: 2px solid #ff00ff;
  border-radius: 5px;
  box-shadow: inset 0 0 20px rgba(255, 0, 255, 0.5);
}

.websiteItem {
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 51, 0.7);
  border: 2px solid #ff00ff;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(255, 0, 255, 0.5);
  animation: shake 0.5s infinite;
}

.websiteItem:hover {
  transform: scale(1.1) rotate(5deg);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.8);
  border-color: #00ff00;
  animation: neon-glow 0.5s infinite alternate;
}

.websiteItem.selected {
  border-color: #00ff00;
  box-shadow: 0 0 30px rgba(0, 255, 0, 0.8);
  animation: selected-item 0.5s infinite alternate;
}

.websiteImage {
  width: 80%;
  height: 80%;
  object-fit: contain;
  margin: 5px auto;
  filter: hue-rotate(0deg);
  animation: hue-rotate 2s linear infinite;
}

.websiteTitle {
  font-size: 1.5em;
  text-align: center;
  margin-top: 5px;
  color: #00ff00;
  text-shadow: 0 0 5px #00ff00;
  animation: text-flicker 1s infinite;
}


.footer {
  text-align: center;
  font-size: 0.8em;
  color: #ff00ff;
  animation: blink 0.5s infinite;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff;
}

.iframeContainer {
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  background-color: #000022;
  border: 1px solid #00ff00;
  overflow: hidden;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 255, 0, 0.5);
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff00ff;
  color: #000;
  border: 1px solid #00ff00;
  font-size: 16px;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 10px 15px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.closeButton:hover, .closeButton:active {
  background-color: #00ff00;
  color: #000;
  transform: scale(1.1);
}

.insertCoin {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #ffff00;
  text-shadow: 
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000,
     0 0 10px #ffff00,
     0 0 20px #ffff00;
  animation: blink 0.5s infinite, float 2s ease-in-out infinite;
}

.avnuItem {
  position: relative;
  overflow: hidden;
}

.avnuItem::before {
  content: 'BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY BUY ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 28px;
  color: #ff00ff;
  background-color: rgba(0, 255, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.avnuItem:hover::before {
  opacity: 1;
}

.avnuItem:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes flicker {
  0% { opacity: 0.97; }
  5% { opacity: 0.9; }
  10% { opacity: 0.98; }
  15% { opacity: 0.95; }
  20% { opacity: 0.93; }
  25% { opacity: 0.97; }
  30% { opacity: 0.9; }
  35% { opacity: 0.95; }
  40% { opacity: 0.98; }
  45% { opacity: 0.94; }
  50% { opacity: 0.96; }
  55% { opacity: 0.92; }
  60% { opacity: 0.97; }
  65% { opacity: 0.95; }
  70% { opacity: 0.98; }
  75% { opacity: 0.93; }
  80% { opacity: 0.96; }
  85% { opacity: 0.94; }
  90% { opacity: 0.98; }
  95% { opacity: 0.92; }
  100% { opacity: 0.97; }
}

@keyframes scanlines {
  0% { background-position: 0 0; }
  100% { background-position: 0 100%; }
}

@keyframes glitch {
  0% { transform: translate(0); }
  20% { transform: translate(-3px, 3px); }
  40% { transform: translate(-3px, -3px); }
  60% { transform: translate(3px, 3px); }
  80% { transform: translate(3px, -3px); }
  100% { transform: translate(0); }
}

@keyframes rotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes rainbow-text {
  0% { color: #ff0000; }
  16% { color: #ff00ff; }
  32% { color: #0000ff; }
  48% { color: #00ffff; }
  64% { color: #00ff00; }
  80% { color: #ffff00; }
  100% { color: #ff0000; }
}

@keyframes rainbow-border {
  0% { border-color: #ff0000; }
  16% { border-color: #ff00ff; }
  32% { border-color: #0000ff; }
  48% { border-color: #00ffff; }
  64% { border-color: #00ff00; }
  80% { border-color: #ffff00; }
  100% { border-color: #ff0000; }
}

@keyframes neon-glow {
  from { box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #ff00de, 0 0 70px #ff00de, 0 0 80px #ff00de, 0 0 100px #ff00de, 0 0 150px #ff00de; }
  to { box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff00de, 0 0 35px #ff00de, 0 0 40px #ff00de, 0 0 50px #ff00de, 0 0 75px #ff00de; }
}

@keyframes selected-item {
  from { box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #00ff00, 0 0 70px #00ff00, 0 0 80px #00ff00, 0 0 100px #00ff00, 0 0 150px #00ff00; }
  to { box-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #00ff00, 0 0 35px #00ff00, 0 0 40px #00ff00, 0 0 50px #00ff00, 0 0 75px #00ff00; }
}

@keyframes hue-rotate {
  0% { filter: hue-rotate(0deg); }
  100% { filter: hue-rotate(360deg); }
}

@keyframes text-flicker {
  0% { opacity: 0.1; }
  2% { opacity: 1; }
  8% { opacity: 0.1; }
  9% { opacity: 1; }
  12% { opacity: 0.1; }
  20% { opacity: 1; }
  25% { opacity: 0.3; }
  30% { opacity: 1; }
  70% { opacity: 0.7; }
  72% { opacity: 0.2; }
  77% { opacity: 0.9; }
  100% { opacity: 0.9; }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@media (max-width: 768px) {
  .console {
    border-width: 4px;
    padding: 10px;
  }
  
  .title {
    font-size: 0.8em;
  }
  
  .websiteGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px; /* Reduced gap for mobile view */
  }
  
  .websiteTitle {
    font-size: 0.5em;
  }
  
  .insertCoin {
    font-size: 1.6em;
    text-shadow: 
      -0.5px -0.5px 0 #000,  
       0.5px -0.5px 0 #000,
      -0.5px  0.5px 0 #000,
       0.5px  0.5px 0 #000,
       0 0 5px #ffff00,
       0 0 10px #ffff00;
  }
}

@media (max-width: 480px) {
  .console {
    border-width: 2px;
    padding: 5px;
  }
  
  .title {
    font-size: 1.3em;
  }
  
  .websiteGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 3px; /* Further reduced gap for smaller screens */
  }
  
  .insertCoin {
    font-size: 1.2em;
  }
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.consoleActive::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(90deg, rgba(255, 0, 0, 0.2), rgba(0, 255, 0, 0.2), rgba(0, 0, 255, 0.2));
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  animation: scanlines 0.5s linear infinite;
  z-index: 2;
}

.footerContainer {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 3;
}

.caText {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.2em;
  color: #00ff00;
  text-shadow: 0 0 5px #00ff00;
  cursor: pointer;
  padding: 75px 10px;
  /* background: rgba(0, 0, 0, 0.5); */
  border-radius: 4px;
  animation: text-flicker 1.5s infinite;
}

.caContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.caDesktop {
  display: block;
}

.caMobile {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.caText:hover {
  color: #ffff00;
  text-shadow: 0 0 5px #ffff00;
}

.copyIcon {
  color: inherit;
  animation: pulse 2s infinite;
  flex-shrink: 0;
}

.copiedMessage {
  font-size: 0.8em;
  color: #4ade80;
  text-shadow: 0 0 5px #4ade80;
  margin-left: 8px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .caText {
    font-size: 0.5em;
  }
  
  .caDesktop {
    display: none;
  }
  
  .caMobile {
    display: flex;
  }
  
  .footerContainer {
    gap: 4px;
  }
}
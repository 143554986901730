.easterEgg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .easterEggImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }